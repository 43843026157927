import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Solid",
  sku: "BW74",
  availability: "In stock",
  title: "OCSO Solid Men's Track Pant BW74",
  mrpPrice: "1640",
  sellPrice: "899",
  disc:
    "OCSO solid men's track pant, A pair of black self-striped knitted mid-rise track pants, has an elasticated waistband with an inner drawstring fastening, two pockets Engineered with Swift Dri technology",
};
export default () => {
  return <ProdLayout data={data} />;
};
